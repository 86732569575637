h1,p,h3 {
  color: white;
}

#header {
  padding: .5em;
  background-color: #00293C;
  text-align: center;
}

.alert-alex {
  background-color: #00293C;
  color: #FFFFFF;
}

.btn-alex {
  background-color: #F62A00 !important;
  color: #FFFFFF !important;
}

.btn-ivory {
  background-color: #F1F3CE !important;
}

.zid {
  font-size: small;
}

#row1 {
  margin-right: -7.5px;
}

#hr1 {
  color: #FFFFFF;
}

#c1 {
  background-color: #00293C;
  color: #FFFFFF;
}

#ch1 {
  background-color: #002231;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
